import UserTopNav from '../../components/UserTopNav.vue';
import JwtServices from '../../services/jwt.services';

export default {
	components: { UserTopNav },
	data: () => ({
		navigationModal: false,
		selected_modal: [],
		network_operator: null,
		modal_title: '',
		items: [
			{
				title: 'Submit Alarm',
				data: [
					{
						name: 'Upload File',
						link: '/dashboard/submit-alarm/upload'
					}
					// {
					//   name: "Create Form",
					//   link: "/dashboard/submit-alarm/form",
					// },
				]
			}
		],
		user: null
	}),
	mounted() {
		this.user = JwtServices.getUser();
		if (!this.user) {
			this.$router.replace({ name: 'Login' });
		}

		if (this.user.network_operator.type) {
			this.network_operator = this.user.network_operator.type;
		}

		// console.log(this.network_operator, " type");
	},
	methods: {
		showModalNavigation(value) {
			this.modal_title = value;
			for (let item of this.items) {
				if (item.title == value) {
					this.selected_modal = item.data;
					this.navigationModal = true;
				}
			}
		}
	}
};
