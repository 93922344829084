<template>
	<div class="text-left">
		<v-card
			v-if="currentPage != 'Landing Page' && mobile == false"
			:elevation="2"
			rounded="lg"
			class="pa-5 pl-10"
		>
			<v-row justify="center" align="center">
				<v-col cols="12" lg="8">
					<div class="headline font-weight-bold">
						{{ currentPage }}
					</div>
				</v-col>
				<v-col cols="12" lg="4" class="text-right">
					<v-menu offset-y>
						<template v-slot:activator="{ on, attrs }">
							<v-chip color="info" pill class="pa-6 mt-5 mr-5" v-on="on">
								<v-avatar
									class="mr-2"
									v-if="user.network_operator.logo == null"
									:color="avatarBackground(user.network_operator.name)"
								>
									<span class="white--text">{{
										stringInitial(user.network_operator.name)
									}}</span>
								</v-avatar>
								<v-avatar v-else left>
									<v-img :src="`${baseURL}${user.network_operator.logo}`"></v-img>
								</v-avatar>
								{{ user.network_operator.name }}
							</v-chip>
						</template>
						<v-list>
							<v-list-item @click="logout">
								<v-list-item-title>Logout</v-list-item-title>
							</v-list-item>
							<router-link to="/auth/reset-password">
								<v-list-item>
									<v-list-item-title>Change Password</v-list-item-title>
								</v-list-item>
							</router-link>
						</v-list>
					</v-menu>
				</v-col>
			</v-row>
			<v-spacer></v-spacer>
		</v-card>
		<div v-else>
			<v-menu offset-y>
				<template v-slot:activator="{ on, attrs }">
					<v-chip color="info" pill class="pa-6 mt-5 mr-5" v-on="on">
						<v-avatar
							class="mr-2"
							v-if="user.network_operator.logo == null"
							:color="avatarBackground(user.network_operator.name)"
						>
							<span class="white--text">{{
								stringInitial(user.network_operator.name)
							}}</span>
						</v-avatar>
						<v-avatar v-else left>
							<v-img :src="`${baseURL}${user.network_operator.logo}`"></v-img>
						</v-avatar>
						{{ user.network_operator.name }}
					</v-chip>
				</template>
				<v-list>
					<v-list-item @click="logout">
						<v-list-item-title>Logout</v-list-item-title>
					</v-list-item>

					<router-link to="/auth/reset-password">
						<v-list-item>
							<v-list-item-title>Change Password</v-list-item-title>
						</v-list-item>
					</router-link>
				</v-list>
			</v-menu>
			<div v-if="currentPage != 'Landing Page'" class="headline font-weight-bold mt-5">
				{{ currentPage }}
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import JwtServices from '../services/jwt.services';
import { ListViewPlugin } from '@syncfusion/ej2-vue-lists';
import { AUTH_SIGNOUT } from '../store/auth.module';

Vue.use(ListViewPlugin);
export default {
	data: () => ({
		accountList: [{ text: 'Logout', id: '002', icon: 'logout', to: 'Login' }],
		fields: { iconCss: 'icon', tooltip: 'text' },
		logoutPopup: false,
		mobile: false,
		user: '',
		baseURL: process.env.VUE_APP_API_URL
	}),
	computed: {
		currentPage() {
			return this.$store.getters.CurrentPage;
		}
	},
	created() {
		if (
			this.$vuetify.breakpoint.name === 'md' ||
			this.$vuetify.breakpoint.name === 'sm' ||
			this.$vuetify.breakpoint.name === 'xs'
		) {
			this.mobile = true;
		}

		this.user = JwtServices.getUser();
		this.baseURL = process.env.VUE_APP_API_URL;
	},
	methods: {
		AccountMenu: function(event) {
			this.logoutPopup = !this.logoutPopup;
			this.logoutPopupClicked = true;
			if (event != undefined) {
				if (event.data.text == 'Settings') {
					this.$router.push({ name: 'Profile' });
				}
				if (event.data.text == 'Logout') {
					this.$router.push({ name: 'Login' });
				}
			}
		},
		logout() {
			this.$store.dispatch(AUTH_SIGNOUT, this.user.id);
			this.$router.push({ name: 'Login' });
		}
	}
};
</script>
