import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueSweetalert2 from "vue-sweetalert2";

import "./assets/sass/main.scss";
import "sweetalert2/dist/sweetalert2.min.css";

//mixins
import AvatarMixins from "./mixins/AvatarMixins";
Vue.mixin(AvatarMixins);

Vue.config.productionTip = false;
Vue.use(VueSweetalert2);

new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount("#app");
