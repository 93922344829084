import Vue from "vue";
import VueRouter from "vue-router";
import LandingPage from "../views/layout/LandingPage.vue";
import Home from "../views/layout/Home.vue";
import store from "../store/index";
import { GET_CURRENT_PAGE, GET_SETTING } from "../store/auth.module";
import JwtServices from "../services/jwt.services";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Landing Page",
		component: LandingPage,
		meta: {
			requiresAuth: true,
		},
	},
	{
		path: "/dashboard",
		name: "Home",
		component: Home,
		children: [
			{
				path: "submit-alarm",
				name: "Submit Alarm",
				component: () =>
					import("../views/pages/submit-alarm/SubmitAlarm.vue"),
				children: [
					{
						path: "upload",
						name: "Submit Alarm - Upload File",
						component: () =>
							import(
								"../views/pages/submit-alarm/UploadFile.vue"
							),
						meta: {
							requiresAuth: true,
						},
					},
					{
						path: "submit/success",
						name: "Submit Alarm - Success",
						component: () =>
							import(
								"../views/pages/submit-alarm/SubmitAlarmSuccess.vue"
							),
						meta: {
							requiresAuth: true,
						},
					},
					// {
					//   path: 'form',
					//   name: 'Submit Alarm - Create Form',
					//   component: () => import('../views/pages/submit-alarm/InputForm.vue'),
					// }
				],
			},
			{
				path: "update-status-alarm",
				name: "Update Status Alarm",
				component: () =>
					import(
						"../views/pages/update-status-alarm/UpdateStatusAlarm.vue"
					),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "form-update-status-alarm",
				name: "Form Update Status Alarm",
				component: () =>
					import("../views/pages/update-status-alarm/UpdateForm.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "history-alarm",
				name: "History Alarm",
				component: () =>
					import("../views/pages/history-alarm/HistoryAlarm.vue"),
				meta: {
					requiresAuth: true,
				},
			},
			{
				path: "history-alarm-fo",
				name: "History Alarm FO",
				component: () =>
					import("../views/pages/history-alarm/HistoryAlarmFO.vue"),
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
	{
		path: "/auth",
		name: "Auth",
		component: () => import("../views/pages/auth/Auth.vue"),
		children: [
			{
				name: "Change Password",
				path: "change-password",
				component: () =>
					import("../views/pages/auth/ChangePassword.vue"),
			},
			{
				name: "Login",
				path: "login",
				component: () => import("../views/pages/auth/Login.vue"),
			},
			{
				name: "Forgot Password",
				path: "forgot-password",
				component: () =>
					import("../views/pages/auth/ForgotPassword.vue"),
			},
			{
				name: "Reset Password",
				path: "reset-password",
				component: () =>
					import("../views/pages/auth/ResetPassword.vue"),
				meta: {
					requiresAuth: true,
				},
			},
		],
	},
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	store.dispatch(GET_CURRENT_PAGE, to.name);

	let token = JwtServices.getToken != null;
	const isAuth = to.matched.some((record) => record.meta.requiresAuth);

	if (to.name == "login" && token) {
		next({
			name: "LandingPage",
		});
	} else if (isAuth) {
		store.dispatch(GET_SETTING);
		next();
	} else {
		next();
	}
});

export default router;
